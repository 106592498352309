'use client';

import React from 'react';
import classnames from 'classnames';
import CSS from './index.module.scss';

interface Props {
  show: boolean;
  onClose: () => void;
  useCloseArrow?: boolean;
  className?: string;
  modalLeft?: React.ReactElement;
  modalRight?: React.ReactElement;
}

export function Modal({
  show,
  onClose,
  modalLeft,
  modalRight,
  useCloseArrow = true,
  className,
}: Props) {
  const [showWithAnimation, setShowWithAnimation] = React.useState(false);

  const onClickClose = function () {
    onClose();
    const origin = new URL(window.location.href).origin;
    window.history.pushState({ path: origin }, '', origin);
  };

  React.useEffect(() => {
    const action = show ? 'add' : 'remove';
    document.body.classList[action]('modal-open');
    const id = window.requestAnimationFrame(() => {
      setShowWithAnimation(show);
    });
    return () => {
      window.cancelAnimationFrame(id);
      document.body.classList.remove('modal-open');
    };
  }, [show]);

  React.useEffect(() => {
    const closeModal = (e: KeyboardEvent) => {
      if (e.key === 'Escape' || e.key === 'Esc' || e.keyCode === 27) {
        onClose();
      }
    };
    window.addEventListener('keydown', closeModal);
    return () => {
      window.removeEventListener('keydown', closeModal);
    };
  }, [onClose]);

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={classnames(
        className,
        CSS['modal'],
        showWithAnimation ? CSS['isVisible'] : null
      )}
      hidden={!show}
      onClick={onClickClose}
    >
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className={classnames(
          CSS['modal-content'],
          useCloseArrow ? CSS['close-arrow-active'] : null
        )}
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className={classnames(CSS['close'], CSS['close-x'])}
          aria-label="Chiudi la modale"
          onClick={onClickClose}
        >
          <svg
            className="close-x-modal"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <path d="M13.4017611,12 L19.7096858,18.3079247 C20.0967714,18.6950104 20.0967714,19.3226002 19.7096858,19.7096858 C19.3226002,20.0967714 18.6950104,20.0967714 18.3079247,19.7096858 L12,13.4017611 L5.69207527,19.7096858 C5.30498964,20.0967714 4.67739984,20.0967714 4.29031422,19.7096858 C3.90322859,19.3226002 3.90322859,18.6950104 4.29031422,18.3079247 L10.5982389,12 L4.29031422,5.69207527 C3.90322859,5.30498964 3.90322859,4.67739984 4.29031422,4.29031422 C4.67739984,3.90322859 5.30498964,3.90322859 5.69207527,4.29031422 L12,10.5982389 L18.3079247,4.29031422 C18.6950104,3.90322859 19.3226002,3.90322859 19.7096858,4.29031422 C20.0967714,4.67739984 20.0967714,5.30498964 19.7096858,5.69207527 L13.4017611,12 Z" />
          </svg>
        </button>
        <button
          className={classnames(CSS['close'], CSS['close-arrow'])}
          onClick={onClickClose}
          aria-label="Chiudi la modale"
        >
          <img
            src={`${process.env.NEXT_PUBLIC_ASSETS_BASE}/static/icons/cactus/arrow-down.svg`}
            width="24"
            height="24"
            alt=""
          />
        </button>

        <div className={CSS['modal-inner']}>
          <div className={CSS['modal-sx']}>{modalLeft}</div>
          <div className={CSS['modal-dx']}>{modalRight}</div>
        </div>
      </div>
    </div>
  );
}
