import React from 'react';
import classnames from 'classnames';
import CSS from '../index.module.scss';

export function DesktopCategory({
  showList,
  listItems,
}: {
  showList: boolean;
  listItems: Array<React.ReactElement>;
}) {
  return (
    <ul
      className={classnames(
        CSS['lt-allCat'],
        CSS['desktop-category'],
        showList ? CSS['lt-allCat-visible'] : null
      )}
      id="listVerticalCont"
    >
      {listItems}
    </ul>
  );
}
