import React from 'react';
import cn from 'classnames';
import { AdTypes, ManageAdsItem } from '@sbt-web/networking';
import {
  BodyText,
  ButtonLink,
  ButtonSize,
  ButtonType,
  ItemDateLocation,
  ItemPrice,
  ItemPriceSize,
  TextSize,
  TextWeight,
} from '@sbt-web/ui';
import style from './index.module.scss';
import { PromoteCardImage } from './CardImage';

export interface PromoteCardProps {
  item: ManageAdsItem;
  assetsBase: string;
  onlyChild: boolean;
  isMobile: boolean;
  href: string;
  onClick: () => void;
  classes?: Array<string>;
}

export const PromoteCard = ({
  item,
  assetsBase,
  onlyChild,
  href,
  classes,
  isMobile,
  onClick,
}: PromoteCardProps) => {
  return (
    <div
      className={cn(
        style['card'],
        {
          [style['card--only-child']]: onlyChild,
        },
        classes
      )}
    >
      <PromoteCardImage
        item={item}
        imageRule={
          isMobile
            ? 'card-mobile-new-small'
            : onlyChild
              ? 'ad-promote-desktop-unique'
              : 'ad-promote-desktop-carousel'
        }
        assetsBase={assetsBase}
        className={style['card__thumb']}
      />

      <p className={style['card__info']}>
        <BodyText
          weight={TextWeight.Semibold}
          size={TextSize.Small}
          classes={[style['card__info__title']]}
        >
          {item.subject}
        </BodyText>

        {item.geo.town?.value && item.geo.city?.shortName && item.date && (
          <ItemDateLocation
            gallerized={true}
            date={item.date}
            town={item.geo.town.value}
            cityShortName={item.geo.city.shortName}
            className={style['card__info__geo']}
          />
        )}

        {item.features['/price'] && (
          <ItemPrice
            adType={item.type.key as AdTypes}
            price={parseInt(item.features['/price'].values[0].key, 10)}
            size={
              !isMobile && onlyChild ? ItemPriceSize.Large : ItemPriceSize.Small
            }
            classes={[style['card__info__price']]}
          />
        )}
      </p>

      <ButtonLink
        type={ButtonType.SolidLite}
        size={onlyChild && !isMobile ? ButtonSize.Large : ButtonSize.Small}
        href={href}
        rel={'nofollow'}
        classes={[style['card__cta']]}
        onClick={onClick}
      >
        Aumenta visibilità
      </ButtonLink>
      <a
        href={href}
        rel={'nofollow'}
        aria-label={`Aumenta visibilità ${item.subject}`}
        className={style['card__whole-clickable-layer']}
        onClick={onClick}
      >
        {' '}
      </a>
    </div>
  );
};
