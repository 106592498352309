import React from 'react';
import { Modal } from '../../../Modal';
import type { Vertical } from '../../../categories';
import CSS from '../index.module.scss';

export const MobileCategory = function ({
  onClose,
  showList,
  listItems,
  vertical,
}: {
  vertical: Vertical;
  onClose: () => void;
  showList: boolean;
  listItems: Array<React.ReactElement>;
}) {
  return (
    <Modal
      className={CSS['mobile-category']}
      modalRight={
        <div className={CSS['list-mobile-wrapper']}>
          <span className={CSS['title-cat']}>
            Seleziona una categoria {vertical.label}
          </span>
          <ul className={CSS['list-mobile']}>{listItems}</ul>
        </div>
      }
      modalLeft={
        <span className={CSS['title-cat-mobile-modal']}>Categoria</span>
      }
      show={showList}
      onClose={onClose}
    />
  );
};
