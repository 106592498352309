import {
  CategoryStore,
  CategoryId,
  GeoEntry,
  buildListingURL,
  BaseCategory,
  HereAddress,
} from '@sbt-web/networking';
import type { CategoryInfo } from './types';

interface RadiusSearch {
  center: { lat: number; lng: number };
  radiusMeters: number;
  friendlyName: string;
  addressToTrack?: HereAddress;
}

interface Geo {
  geoValues: GeoEntry;
  radiusValues: RadiusSearch | null;
  includeNearbyRegions?: boolean;
}

export interface Filters {
  filters?: Record<string, string>;
  qso: boolean;
  shp: boolean;
  urg?: boolean;
}

export interface GeoLocation {
  geo: GeoEntry;
  includeNearbyRegions?: boolean;
}

type LocationData = GeoLocation & {
  radiusSearch?: RadiusSearch | null;
};

export const navigateToListing = (
  query?: string,
  categoryID?: CategoryId,
  location?: LocationData,
  includeShipping?: boolean,
  allFilters?: Filters
): void => {
  const category = CategoryStore.getCategoryById(
    categoryID || CategoryId.Tutte
  );

  const listingURL = buildListingURL({
    category,
    query,
    geo: location?.geo,
    includeNearbyRegions: location?.includeNearbyRegions,
    radiusSearch: location?.radiusSearch || undefined,
  });

  let searchUrl = process.env.NEXT_PUBLIC_ENV_ORIGIN + listingURL;

  if (includeShipping) {
    searchUrl = addQueryToSearchUrl(searchUrl, 'true', 'shp');
  }

  if (location?.radiusSearch) {
    searchUrl = addQueryToSearchUrl(
      searchUrl,
      location.radiusSearch?.radiusMeters?.toString(),
      'rad'
    );
    searchUrl = addQueryToSearchUrl(
      searchUrl,
      location.radiusSearch?.center?.lat?.toString(),
      'lat'
    );
    searchUrl = addQueryToSearchUrl(
      searchUrl,
      location.radiusSearch?.center?.lng?.toString(),
      'lon'
    );
  }

  if (allFilters !== undefined) {
    const { qso, filters } = allFilters;

    if (qso) {
      searchUrl = addQueryToSearchUrl(searchUrl, 'true', 'qso');
    }

    if (filters !== undefined) {
      for (const key in filters) {
        searchUrl = addQueryToSearchUrl(searchUrl, filters[key], key);
      }
    }
  }

  window.location.assign(searchUrl);
};

export const addQueryToSearchUrl = (
  searchUrl: string,
  searchValue: string,
  searchKey: string
): string => {
  if (searchValue === '') {
    return searchUrl;
  }

  try {
    const parsedSearchUrl = new URL(searchUrl);
    parsedSearchUrl.searchParams.append(searchKey, searchValue);
    return parsedSearchUrl.href;
  } catch (e) {
    if (searchUrl.indexOf('?') !== -1) {
      return `${searchUrl}&${searchKey}=${searchValue}`;
    } else {
      return `${searchUrl}?${searchKey}=${searchValue}`;
    }
  }
};

export const recentSearch = (
  query: string | undefined,
  category: BaseCategory,
  geo: Geo,
  includeShipping?: boolean,
  filters?: Filters
): void => {
  const location = {
    geo: geo?.geoValues,
    radiusSearch: geo?.radiusValues ?? null,
    includeNearbyRegions: geo?.includeNearbyRegions ?? false,
  };

  navigateToListing(query, category?.id, location, includeShipping, filters);
};

export const translateParamsToUrl = function (
  query: string | undefined,
  category: CategoryInfo | undefined,
  geo: GeoLocation | undefined,
  includeShipping?: boolean,
  allFilters?: Filters
): void {
  navigateToListing(query, category?.key, geo, includeShipping, allFilters);
};
