import { usePublicUser } from '@sbt-web/auth';
import { Carousel } from '@sbt-web/carousel';
import '@sbt-web/carousel/style';
import ErrorBoundary from '@sbt-web/error-boundary';
import { useViewport } from '@sbt-web/hooks';
import { OptimizelySubitoContext } from '@sbt-web/houston-wrapper';
import { AndromedaClient, ManageAdsItem } from '@sbt-web/networking';
import { Headline5, Headline6 } from '@sbt-web/ui';
import cn from 'classnames';
import React, { useContext, useEffect, useState } from 'react';
import { PromoteCard } from './card';
import style from './index.module.scss';
import { trackItemClick, trackView } from './tracking';

export interface PromoteWidgetProps {
  reservedAreaBase: string;
  assetsBase: string;
  andromedaClient: AndromedaClient;
  classes?: Array<string>;
}

export const fetchItems = async (
  andromedaClient: AndromedaClient,
  userId: string
): Promise<Array<ManageAdsItem>> => {
  const MAX_ADS_TO_RETURN = 5;
  const { userAds } = await andromedaClient.getAds(userId, {
    limit: 10,
  });

  if (!userAds || !userAds?.total || userAds?.total > 10) {
    return []; // not eligible
  }
  // return the eligible ads
  return userAds.ads
    .filter((ad) => {
      const hasntAnActiveTransaction = !ad.features['/transaction_status'];
      const hasNoPO = !ad.paidOptions?.length;

      // the ad is eligible if it hasn't an active transaction and it has no PO
      return hasntAnActiveTransaction && hasNoPO;
    })
    .sort((a: ManageAdsItem, z: ManageAdsItem) => {
      const sortByViews =
        (a.statistics?.views ?? 0) - (z.statistics?.views ?? 0);
      if (sortByViews) {
        return sortByViews;
      }

      const sortByMessages =
        (a.statistics?.messages ?? 0) - (z.statistics?.messages ?? 0);
      if (sortByMessages) {
        return sortByMessages;
      }

      return new Date(a.date).getTime() - new Date(z.date).getTime();
    })
    .slice(0, MAX_ADS_TO_RETURN);
};

const Widget = ({
  andromedaClient,
  assetsBase,
  reservedAreaBase,
  classes,
}: PromoteWidgetProps) => {
  const user = usePublicUser();
  const { isMobile, isDesktop } = useViewport();
  const { optimizely } = useContext(OptimizelySubitoContext);
  const [ready, setReady] = useState(false);
  const [items, setItems] = useState<Array<ManageAdsItem>>([]);

  const fetchData = async (userId: string) => {
    setItems(await fetchItems(andromedaClient, userId));
  };

  useEffect(() => {
    if (user) {
      fetchData(user.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (!ready && items.length) {
      setReady(true);
      trackView();
    }
  }, [items, ready]);

  if (items.length) {
    const cards = items.map((ad, index) => (
      <li key={ad.urn}>
        <PromoteCard
          item={ad}
          assetsBase={assetsBase}
          onlyChild={items.length === 1}
          isMobile={isMobile}
          href={`${reservedAreaBase}/annunci/promuovi/${ad.urn}?entry_point=HOMEPAGE`}
          onClick={() => {
            trackItemClick(index + 1, ad);
            optimizely?.track('click_promote_hp_polaris');
          }}
        />
      </li>
    ));

    return (
      <section
        className={cn(
          style['promote-widget'],
          { [style['promote-widget--ready']]: ready },
          style[`promote-widget--with-${items.length}`],
          classes
        )}
      >
        {isMobile ? (
          <Headline6 classes={[style['promote-widget__head']]}>
            Promuovi i tuoi annunci
          </Headline6>
        ) : (
          <Headline5 classes={[style['promote-widget__head']]}>
            Promuovi i tuoi annunci
          </Headline5>
        )}
        <Carousel
          cssClass={cn(
            style['promote-widget__carousel'],
            'slider-nav-round',
            'slider-nav-visible'
          )}
          currentIndex={0}
          showButtons={isDesktop && items.length > 3}
          listItems={cards}
          labelArrows={{
            left: 'Indietro',
            right: 'Avanti',
          }}
        />
      </section>
    );
  }

  return null;
};

export const PromoteWidget = (props: PromoteWidgetProps) => (
  <ErrorBoundary>
    <Widget {...props} />
  </ErrorBoundary>
);
