import React from 'react';
import classnames from 'classnames';
import { useViewport } from '@sbt-web/hooks';
import { DesktopCategory } from './Desktop';
import { MobileCategory } from './Mobile';
import type { Vertical } from '../../../categories';
import type { CategoryInfo } from '../../../types';
import CSS from '../index.module.scss';
import type { CategoryMinimal } from '../../../categories';

const getItem = function (
  cat: CategoryMinimal,
  onClickCategory: (c: CategoryMinimal) => void,
  cssClasses?: string
) {
  return (
    <button
      className={classnames(CSS['cat-item-list'], cssClasses)}
      type="button"
      value={cat.id}
      onClick={() => onClickCategory(cat)}
    >
      {cat.label}
    </button>
  );
};

export const getListItems = function (
  vertical: Vertical,
  onClickCategory: (c: CategoryMinimal) => void
) {
  return vertical.categories.map((cat) => (
    <React.Fragment key={cat.id}>
      <li key={cat.id}>
        {getItem(
          cat,
          onClickCategory,
          cat.categories !== undefined ? CSS['bold'] : undefined
        )}
      </li>
      {cat.categories
        ? cat.categories.map((subcategory) => (
            <li key={subcategory.id}>
              {getItem(subcategory, onClickCategory)}{' '}
            </li>
          ))
        : null}
    </React.Fragment>
  ));
};

export function CategoriesList({
  vertical,
  onChangeCategory,
}: {
  vertical: Vertical;
  onChangeCategory: (c: CategoryInfo) => void;
}) {
  const [showList, setShowList] = React.useState(false);
  const [category, setCategory] = React.useState<CategoryMinimal>(
    vertical.default
  );
  const { isDesktop } = useViewport();

  const onClickCategory = React.useCallback(
    (cat: CategoryMinimal) => {
      setShowList(false);
      setCategory(cat);
      onChangeCategory({ key: cat.id, label: cat.label });
    },
    [onChangeCategory]
  );

  const listItems = React.useMemo(
    () => getListItems(vertical, onClickCategory),
    [onClickCategory, vertical]
  );

  return (
    <div
      className={CSS['category-input-wrapper']}
      onMouseLeave={() => {
        if (isDesktop) {
          setShowList(false);
        }
      }}
    >
      <div className={classnames(CSS['input'], CSS['category-input'])}>
        <img
          src={`${process.env.NEXT_PUBLIC_ASSETS_BASE}/static/icons/cactus/category-squares.svg`}
          width="38"
          height="38"
          alt=""
          className={CSS['input-icon']}
        />
        <button
          type="button"
          id="desktop-modal-category-selection"
          className={CSS['input-inner']}
          onClick={() => setShowList(!showList)}
        >
          {category.label}
        </button>
        <input type="hidden" value="0" id="modal-category-field" />
        <div
          className={classnames(
            CSS['input-icon'],
            CSS['input-arrow'],
            showList ? CSS['input-arrow-rotate'] : null
          )}
        >
          <img
            src={`${process.env.NEXT_PUBLIC_ASSETS_BASE}/static/icons/cactus/arrow-down.svg`}
            alt=""
            width="16"
            height="16"
          />
        </div>
      </div>
      {isDesktop ? (
        <DesktopCategory listItems={listItems} showList={showList} />
      ) : (
        <MobileCategory
          vertical={vertical}
          listItems={listItems}
          showList={showList}
          onClose={() => setShowList(false)}
        />
      )}
    </div>
  );
}
